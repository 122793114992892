<template>
    <ExamineItem v-if="selected_item" :product="selected_item" @close="selected_item = null"/>
    <div class="products-to-attend">
        <div class="tools">
            <el-button type="primary" class="tool-btn" @click="filter = ''">כל החריגים</el-button>
            <el-button type="primary" class="tool-btn" @click="filter = 'תמונה'">חסרה תמונה</el-button>
            <el-button type="primary" class="tool-btn" @click="filter = 'קטגוריה'"> קטגוריה</el-button>
            <el-button type="primary" class="tool-btn" @click="filter = 'אריזה'">אריזה</el-button>
            <el-button type="primary" class="tool-btn" @click="filter = 'כשרות'">כשרות</el-button>
            <el-button type="success" class="tool-btn" @click="handle_export_to_excel">יצא לאקסל</el-button>
        </div>
        <div class="table-box">
            <table id="table">
                <tr>
                    <th>מקט</th>
                    <th>שם</th>
                    <th>חריג</th>
                    <th>עדכון אחרון</th>
                    <th></th>
                </tr>
                <template v-for="item in sorted_products" :key="item.makat">
                    <tr v-if="item.errors.length > 0">
                        <td>{{item.makat}}</td>
                        <td><span :class="get_item_class(item)">{{item.status}}</span>  {{item.name}}</td>
                        <td>{{item.errors}}</td>
                        <td>{{new Date(item.last_update.toDate()).toLocaleDateString('he')}}</td>
                        <td><i class="material-icons" style="cursor:pointer;" @click="selected_item = item">settings_applications</i></td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core';
import { projectFirestore } from '../../../firebase/config';
import ExamineItem from './ItemExamine.vue';
import XLSX from "xlsx";

export default {
components:{ExamineItem},
setup(){
    const products = ref([]);
    const filter = ref('');
    const selected_item = ref(null);

    const sorted_products = ref(computed(() => {
        if(!filter.value){
            return products.value
        }
        else{
            return products.value.filter(item => {
                return item.errors.includes(filter.value)
            })
        }
    }))

    const get_products = async () => {
        const docs = await projectFirestore.collection('Products').get();
        if(!docs.empty){
            var temp = docs.docs.map(doc => doc.data());
            for(let item in temp){
                let product = temp[item];
                product.errors = [];
                if(product.image == 'חסרה תמונה'){
                    product.errors.push('תמונה')
                }
                if(product.category == 'אין קטגוריה'){
                    product.errors.push('קטגוריה')
                }
                if(product.sub_category == 'אין תת קטגוריה'){
                    product.errors.push('תת קטגוריה')
                }
                if(!product.boxSize){
                    product.errors.push('אריזה')
                }
                if(!product.kosher_type){
                    product.errors.push('כשרות')
                }
                products.value.push(product);
            }
        }
    }

    const get_item_class = (item) => {
        switch (item.status) {
            case 'פעיל':
                return "active"
            case 'אסור למכירה':
                return "not-active"
            case 'לא פעיל':
                return 'not-active'
            default:
                return ''
        }
    }

    const handle_export_to_excel = ()=>{
        let rows = JSON.parse(JSON.stringify(sorted_products.value))
        let workbook = XLSX.utils.book_new();
        let workSheetData =[]
        workSheetData.push(['מק"ט','שם','חריג','עדכון אחרון'])
        rows.forEach(row=>{
            workSheetData.push(
                [
                    row.makat,
                    row.name,
                    row.errors.join(", "),
                    new Date(row.last_update.seconds * 1000).toLocaleDateString('he')
                ]
            )
        })
        const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
        workbook.Workbook = {};
        workbook.Workbook.Views = [];
        workbook.Workbook.Views[0] = {};
        workbook.Workbook.Views[0].RTL = true;
        XLSX.utils.book_append_sheet(workbook, workSheet, "פריטים חריגים");
        XLSX.writeFile(workbook, "פריטים חריגים.xlsx");
    }

    onMounted(() => {
        get_products();
    })

    return{
        sorted_products, filter, selected_item, get_item_class,
        handle_export_to_excel
    }
}
}
</script>

<style scoped>
.products-to-attend{
    width: 100%;
    height: 100%;
}
.tools{
    width: 100%;
    height: 80px;
    background: var(--alert-purple);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 5px;
    overflow-x: auto;
}
.tool-btn{
    height: 100%;
    margin-left: 8px;
}
.table-box{
    width: 100%;
    height: calc(100% - 80px);
    overflow-y: auto;
}
#table {
    margin-top: 10px;
  border-collapse: collapse;
  width: 100%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}

#table tr:hover {background-color: #ddd;}

#table td:last-child{
    text-align: center;
    color: var(--secondary);
}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
}
.active{
    color: var(--success);
    font-size: 12px;
}
.not-active{
    color: var(--danger);
    font-size: 12px;
}
</style>